import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import logger from 'saddlebag-logger';

export default class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, info) {
    logger.logError(error, {
      component: this.props.component,
      message: error.toString(),
      stacktrace: info.componentStack,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children, fallback = null } = this.props;
    return hasError ? fallback : children;
  }
}

ErrorBoundary.propTypes = {
  component: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/require-default-props
  fallback: PropTypes.node,
};
