import PropTypes from 'prop-types';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import { ReactComponent as BagLogo } from '../../assets/bag.svg';

export const Transport = ({
  has_partner_logo: hasPartnerLogo,
  imagePath,
  isB2B,
  isBagsEnabled,
  localisation,
  logo,
  logo_alt_text: logoAltText,
  partner_logo: partnerLogo,
  query_api_pbs: queryApiPbs,
  websiteName,
}) => {
  const {
    ktxtDA_RedirectPage_Step2: ktxtDaRedirectPageStep2,
    ktxtRedirectPage_A11y_BagsMessage: ktxtRedirectPageA11yBagsMessage,
    ktxtRedirectPage_BagsMessage: ktxtRedirectPageBagsMessage,
    ktxtRedirectPage_PoweredBy: ktxtDaRedirectPagePoweredBy,
    ktxtRedirectPage_TakingYouTo: ktxtRedirectPageTakingYouTo,
  } = localisation;
  return (
    <div id="Redirect" className="transport-redirect-container">
      <div className="logo-container">
        {isB2B && <img src={logo} alt={logoAltText} aria-hidden />}
      </div>
      <div className="content">
        <BpkText
          textStyle={TEXT_STYLES.bodyLongform}
          tagName="h1"
          aria-label={ktxtDaRedirectPageStep2}
        >
          {ktxtRedirectPageTakingYouTo}
        </BpkText>
        {hasPartnerLogo ? (
          <img src={partnerLogo} alt={websiteName} className="partner-logo" />
        ) : (
          <div className="provider-nologo">
            <BpkText
              tagName="h4"
              textStyle={TEXT_STYLES.lg}
              className="fallback-text"
            >
              {websiteName}
            </BpkText>
          </div>
        )}
        <div className="transport-loading">
          <div className="transport-indicator" />
        </div>
        {isBagsEnabled && (
          <div className="bags-rectangle">
            <div className="bags-strapline">
              <BpkText
                textStyle={TEXT_STYLES.bodyDefault}
                aria-label={ktxtRedirectPageA11yBagsMessage}
                tagName="p"
                dangerouslySetInnerHTML={{
                  __html: ktxtRedirectPageBagsMessage,
                }}
              />
            </div>
            <BagLogo className="bag-logo" alt="" />
          </div>
        )}
        {queryApiPbs && isB2B && (
          <div className="Summary" id="powered">
            <div className="ssdv-poweredby poweredby-b2b-container">
              <p>
                <span className="poweredby-text">
                  {ktxtDaRedirectPagePoweredBy}
                </span>
                <img
                  src={`${imagePath}/skyscanner-logo_rebrand.png`}
                  alt="Skyscanner"
                />
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Transport.propTypes = {
  logo: PropTypes.string.isRequired,
  logo_alt_text: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
  partner_logo: PropTypes.string.isRequired,
  websiteName: PropTypes.string.isRequired,
  has_partner_logo: PropTypes.bool.isRequired,
  query_api_pbs: PropTypes.bool.isRequired,
  localisation: PropTypes.shape({
    ktxtDA_RedirectPage_Step2: PropTypes.string,
    ktxtRedirectPage_A11y_BagsMessage: PropTypes.string,
    ktxtRedirectPage_BagsMessage: PropTypes.string,
    ktxtRedirectPage_PoweredBy: PropTypes.string,
    ktxtRedirectPage_TakingYouTo: PropTypes.string,
  }).isRequired,
  isB2B: PropTypes.bool.isRequired,
  isBagsEnabled: PropTypes.bool.isRequired,
};
