import PropTypes from 'prop-types';

import { Hotel } from '../Hotel/Hotel';
import { Carhire } from '../Carhire/Carhire';
import { Transport } from '../Transport/Transport';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { Rail } from '../Rail/Rail';

// needed for webpack to buid our main.xxx.css
// eslint-disable-next-line no-unused-vars
import STYLES from './App.scss';

const components = {
  TRANSPORT: Transport,
  HOTEL: Hotel,
  CAR_HIRE: Carhire,
  RAIL: Rail,
};

const FallbackComponent = () => {
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
    },
    text: {
      fontSize: '20px',
      fontWeight: 700,
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.text}>Hold tight, we’re redirecting you...</h2>
    </div>
  );
};

const getVerticalComponent = (vertical, props) => {
  const VerticalComponent = components[vertical];

  return (
    <ErrorBoundary component={vertical} fallback={<FallbackComponent />}>
      <VerticalComponent {...props} />
    </ErrorBoundary>
  );
};

const App = (props) => {
  const { vertical } = props;
  return getVerticalComponent(vertical, props);
};

App.propTypes = {
  vertical: PropTypes.string.isRequired,
};

export default App;
