import scriptLoader from 'saddlebag-scriptloader';

const sharedLibsVersion = '3.3.0';

export const reactVersion = '18.3.1';
export const reactDomVersion = '18.3.1';

export const loadExternalScripts = () =>
  scriptLoader.queue(
    'redirects-ui',
    [
      `/sttc/shared-libs/${sharedLibsVersion}/react/react${reactVersion}.js`,
      `/sttc/shared-libs/${sharedLibsVersion}/react-dom/react-dom${reactDomVersion}.js`,
    ],
    { async: false },
  );
